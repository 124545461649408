import React, { Component } from "react"
//import { getStatusNameFromObj } from "../../../../utils";
import "./style.css"

const OrderHistoryList = (historyRecords, statuses, getFormatedDate) => {
  if (historyRecords.length <= 0) {
    return
  }
  const items = historyRecords.map((element, index) => {
    return {
      orderStatus: statuses[element.orderStatus],
      //orderStatus: getStatusNameFromObj(statuses, element.orderStatus),
      statusDate: getFormatedDate(element.statusDate),
      location: element.location,
      userFullname:
        (element.userFirstName == null ? "" : element.userFirstName) +
        " " +
        (element.userLastName == null ? "" : element.userLastName),
    }
  })
  return (
    <div className="record-table">
      <div className="record-row">
        <div className="record-col icon"></div>
        <div className="record-col status">סטטוס הזמנה</div>
        <div className="record-col status-date">תאריך סטטוס</div>
        <div className="record-col location">מיקום</div>
        <div className="record-col user-fullname">מבצע</div>
      </div>
      {renderItems(items)}
    </div>
  )
}

const renderItems = (items) => {
  return items.map((element, index) => {
    return (
      <div className="record-row" key={index}>
        <div className="record-col icon">{index + 1}</div>
        <div className="record-col status">{element.orderStatus}</div>
        <div className="record-col status-date">{element.statusDate}</div>
        <div className="record-col location">{element.location}</div>
        <div className="record-col user-fullname">{element.userFullname}</div>
      </div>
    )
  })
}

export default OrderHistoryList
