import {
  ON_GET_ORDER_STATUSES_SUCCESS,
  ON_GET_ORDER_STATUSES_FAIL,
} from "../actions/types"

import { setOrderStatusesEnumByLang } from "../utils/OrderStatuses"

const INIT_STATE = {
  ordersStatusesArr: [],
  ordersStatuses_SelectList: [],
}

const OrdersStatusesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_GET_ORDER_STATUSES_SUCCESS:
      return {
        ...state,
        ordersStatusesArr: setStatusesArray(action.payload),
        ordersStatuses_SelectList: setStatuses_SelectList(action.payload),
      }
    case ON_GET_ORDER_STATUSES_FAIL:
      return {
        ...INIT_STATE,
      }
    default:
      return state
  }
}

const setStatusesArray = (statuses) => {
  if (statuses === undefined || statuses === null || statuses.length <= 0)
    return []

  return setOrderStatusesEnumByLang(statuses, "he")
}

const setStatuses_SelectList = (statuses) => {
  if (statuses === undefined || statuses === null || statuses.length <= 0)
    return []

  let index = 0
  const items = []

  items[index] = {
    value: -1,
    label: "כל הסטטוסים",
  }

  const lang = "he"
  for (const [key, value] of Object.entries(statuses)) {
    index++
    switch (lang) {
      case "en":
        items[index] = { label: value.statusName, value: value.id }
        break
      case "he":
        items[index] = { label: value.statusName_he, value: value.id }
        break
      default:
        items[index] = { label: value.statusName_he, value: value.id }
    }
  }
  return items
}

export default OrdersStatusesReducer
