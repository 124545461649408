import {
  ON_START_LOADING,
  ON_FETCH_EXIST_ORDERS_SUCCESS,
  ON_FETCH_EXIST_ORDERS_FAIL,
  ON_CLOSE_MESSAGE,
  RESET_ALL_REDUCERS,
  ON_SEARCH_ORDERS_SUCCESS,
  ON_SEARCH_ORDERS_STOP,
  ON_SEARCH_ORDERS_FAIL,
  ON_SET_SEARCH_ORDERS,
  ON_CLEAR_SEARCH_ORDERS,
} from "../actions/types"

const INIT_STATE = {
  orderList: null,
  order: null,
  errTitle: null,
  errText: null,
  isLoadingSearch: false,
  isError: false,
  showMessage: false,
  searchData: {
    orderNumber: "",
    packageNumber: "",
    mobilePhone: "",
    firstName: "",
    lastName: "",
    station: "",
    orderStatus: -1,
    orderType: 0,
    accountType: 0,
    fromDate: null,
    toDate: null,
    externalNumber: "",
    externalNumber2: "",
    pageStep: 50,
    page: 0,
    nextPageKey: null,
    isStopSearching: false,
  },
}

const SearchOrderReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_START_LOADING:
      return {
        ...state,
        isLoadingSearch: true,
        isStopSearching: false,
      }
    case ON_SEARCH_ORDERS_SUCCESS:
      console.log(
        "!!! ON_SEARCH_ORDERS_SUCCESS  page:" + action.payload.data.page
      )
      let orderList = []
      if (
        action.payload.data &&
        action.payload.data.page > 1 &&
        action.payload.data.items &&
        action.payload.data.items.length > 0
      ) {
        orderList = [...state.orderList, ...action.payload.data.items]
        console.log(
          "     orderList (page " +
            action.payload.data.page +
            ") + " +
            action.payload.data.items.length +
            " items"
        )
      } else if (action.payload.data && action.payload.data.page == 1) {
        orderList = action.payload.data.items
        console.log(
          "     orderList (page " +
            action.payload.data.page +
            ") - set new orderList(" +
            action.payload.data.items.length +
            " items) "
        )
      } else {
        console.log("Reducer ERROR !!! response data:")
        console.log(action.payload.data)
        return {
          ...state,
          isLoadingSearch: false,
          isStopSearching: true,
          showMessage: true,
          isError: true,
        }
      }

      return {
        ...state,
        orderList,
        searchData: {
          ...state.searchData,
          page: action.payload.data.page + 1,
          nextPageKey: action.payload.data.nextPageKey,
        },
        isLoadingSearch: false,
        showMessage: false,
        isError: false,
      }
    case ON_SEARCH_ORDERS_STOP:
      return {
        ...state,
        isLoadingSearch: false,
        isStopSearching: true,
        showMessage: false,
        isError: false,
      }
    case ON_SEARCH_ORDERS_FAIL:
      return {
        ...state,
        orderList: [],
        isLoadingSearch: false,
        showMessage: true,
        isError: true,
      }
    case ON_SET_SEARCH_ORDERS:
      return {
        ...state,
        searchData: {
          ...action.payload,
          page: 1,
          nextPageKey: null,
        },
      }
    case ON_CLEAR_SEARCH_ORDERS:
      return {
        ...state,
        searchData: {
          orderNumber: "",
          packageNumber: "",
          mobilePhone: "",
          firstName: "",
          lastName: "",
          station: "",
          orderStatus: -1,
          orderType: 0,
          accountType: 0,
          fromDate: null,
          toDate: null,
          externalNumber: "",
          externalNumber2: "",
          pageStep: 50,
          page: 1,
          nextPageKey: null,
        },
      }
    case ON_FETCH_EXIST_ORDERS_SUCCESS:
      return {
        ...state,
        orderList: action.payload.data,
        isLoadingSearch: false,
        showMessage: false,
        isError: false,
      }

    case ON_FETCH_EXIST_ORDERS_FAIL:
      return {
        ...state,
        isError: true,
        errTitle: action.payload.errTitle,
        errText: action.payload.errText,
        showMessage: true,
        isLoadingSearch: false,
      }
    case ON_CLOSE_MESSAGE:
      return {
        ...state,
        showMessage: false,
      }
    case RESET_ALL_REDUCERS:
      return {
        ...INIT_STATE,
      }

    default:
      return state
  }
}

export default SearchOrderReducer
