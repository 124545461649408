import React, { Component } from "react"
import { connect } from "react-redux"
import {
  getExternalNumberByPackage,
  clearGetExternalNumber,
} from "../../../actions"
import "./style.scss"

class ScanOrderScreen extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      packageNumber: "",
      externalNumber: "",
    }

    this.state = this.INIT_STATE
  }

  componentWillReceiveProps(newProps) {
    const { authenticated, history, externalOrderNumber, userData } = newProps
    if (!authenticated) {
      history.push("/")
    }
    if (externalOrderNumber) {
      this.setState({
        externalNumber: externalOrderNumber,
      })
    }
  }

  componentWillUnmount() {
    this.props.clearGetExternalNumber()
  }

  handleInputChange = (event) => {
    this.setState({ packageNumber: event.target.value, externalNumber: "" })
    if (event.target.value.length === 5) {
      this.props.getExternalNumberByPackage(event.target.value, 21)
    }
  }

  render() {
    let { packageNumber, externalNumber } = this.state
    const { getExternalOrderNumberSuccess, getExternalOrderNumberFail } =
      this.props

    return (
      <div className="scanOrder">
        <h1>סריקת חבילות</h1>
        <div className="package">
          <label className="label">מספר חבילה:</label>
          <input
            type="text"
            name="packageNumber"
            value={packageNumber}
            onChange={this.handleInputChange}
          />
        </div>
        <div key={externalNumber}>
          <div>
            <label className="label">מספר חיצוני: </label>
            <span className="external-number"> {externalNumber ?? null}</span>
          </div>
        </div>
        {getExternalOrderNumberFail ? (
          <div className="error">הזמנה לא נמצאה</div>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = ({ users, orders }) => {
  const { authenticated, userData } = users
  const {
    externalOrderNumber,
    getExternalOrderNumberSuccess,
    getExternalOrderNumberFail,
  } = orders
  return {
    authenticated,
    externalOrderNumber,
    userData,
    getExternalOrderNumberSuccess,
    getExternalOrderNumberFail,
  }
}

export default connect(mapStateToProps, {
  getExternalNumberByPackage,
  clearGetExternalNumber,
})(ScanOrderScreen)
