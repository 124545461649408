import React, { Component } from "react"
import { connect } from "react-redux"
import { onUpdateMessage, onGetStations, closeMessage } from "../../../actions"
import { Button } from "../../common/Button"
import { LabeledTextarea as TextArea } from "../../common/LabeledTextarea"
import Message from "../../common/Message"
import SelectStationField from "../../common/SelectField/SelectStationField"
import Title from "../../common/Title"
import "./style.scss"

class UpdateMessageScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentStation: {},
      stationsList: [],
      sendSMS: true,
    }
  }

  componentDidMount() {
    const { authenticated, history } = this.props
    if (!authenticated) {
      history.push("/")
    }
    this.props.onGetStations()
  }

  componentDidUpdate(prevProps) {
    const { stationsAll } = this.props
    const { authenticated, history } = prevProps
    if (!authenticated) {
      history.push("/")
    }
    if (prevProps !== this.props) {
      this.selectStationForDecathlon()
    }
  }

  componentWillUnmount() {
    this.setState(this.state)
  }

  selectStationForDecathlon() {
    const { stationsAll, selectedDecathlonStation } = this.props
    if (stationsAll) {
      var result = stationsAll.find((obj) => {
        return obj.stationNumber === selectedDecathlonStation
      })
      if (result) {
        this.setState({ stationsList: [result], currentStation: result })
        return
      }
    }
    this.setState({
      stationsList: stationsAll,
      currentStation: stationsAll[0],
    })
  }

  setValue(inputName, e) {
    const { stationsAll } = this.props

    switch (inputName) {
      case "currentStation":
        this.setState({
          currentStation: stationsAll.find(
            (el) => el.branchDisplayName === e.target.value
          ),
        })
        break
      case "stationsList":
        this.setState({ stationsList: e.target.value })
        break
      case "stationAddress":
        this.setState({
          currentStation: {
            ...this.state.currentStation,
            branchDisplayAddress: e.target.value,
          },
        })
        break
      default:
        break
    }
  }

  render() {
    let { currentStation, stationsList, sendSMS } = this.state
    const {
      onUpdateMessage,
      showMessage,
      isError,
      closeMessage,
      onGetStations,
    } = this.props

    return (
      <div className="updateMessageScreen">
        <Title text="שינוי כתובת בהודעת סמס ללקוח" />
        <div className="inputs">
          <SelectStationField
            options={stationsList}
            emptyOption={false}
            value={currentStation?.branchDisplayName}
            onChange={(e) => {
              this.setValue("currentStation", e)
            }}
          />
          <TextArea
            value={currentStation?.branchDisplayAddress}
            inputSize={400}
            onChange={(e) => {
              this.setValue("stationAddress", e)
            }}
          />
          <div className="checkbox">
            <input
              type="checkbox"
              name="sendSMS"
              id="sendSMS"
              checked={sendSMS}
              onChange={() => {
                this.setState({ sendSMS: !sendSMS })
              }}
            />
            <label htmlFor="sendSMS">שליחת הודעה לכל החבילות בעמדה</label>
          </div>
          <div className="buttons-wrapper">
            <Button
              onClick={() => {
                onUpdateMessage(
                  currentStation.branchDisplayAddress,
                  sendSMS,
                  currentStation.stationId
                )
              }}
            >
              עדכון
            </Button>

            <Button onClick={() => this.props.history.goBack()}>חזור</Button>
          </div>
          <Message
            show={showMessage}
            isError={isError}
            successText="הכתובת השתנתה בהצלחה"
            errorText="אנא בדוק את הפרטים ונסה שוב"
            onClick={() => {
              closeMessage()
              onGetStations()
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ users, station }) => {
  const { authenticated } = users
  const { stationsAll, showMessage, isError, selectedDecathlonStation } =
    station

  return {
    authenticated,
    stationsAll,
    showMessage,
    isError,
    selectedDecathlonStation,
  }
}

export default connect(mapStateToProps, {
  onUpdateMessage,
  onGetStations,
  closeMessage,
})(UpdateMessageScreen)
