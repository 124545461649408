import React, { Component } from "react"
import { connect } from "react-redux"
import StationsFilterer from "../../../filters/StationsFilterer"
import StationsList from "./StationsList"
import { Pagination } from "../../common/Pagination/"
import {
  fetchStationsByRole,
  showStationLoading,
  clearLocalSearchStations,
  localSearchStationsSetPage,
  selectStation,
  onGetStations,
} from "../../../actions"
import { JsonToExcel } from "react-json-to-excel"
import "./style.scss"

class StationsScreen extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      pagedList: [],
    }

    this.state = this.INIT_STATE
  }

  componentDidMount() {
    if (!this.props.authenticated) {
      this.props.history.push("/")
    }
    this.props.fetchStationsByRole()
    this.props.showStationLoading()
  }

  componentWillUnmount() {
    this.props.clearLocalSearchStations()
  }

  resetCurrentPage() {
    this.props.localSearchStationsSetPage(1)
    this.setState({ pagedList: [] })
  }

  onItemStatusBtnClick(item) {
    this.props.selectStation(item)
    this.props.history.push("/StationStatusScreen")
  }

  getColumnsForExportLite(stations) {
    if (stations.length > 0) {
      return stations.map((station, index) => {
        return {
          stationNumber: station.stationNumber,
          branchName: station.branchName,
          lockersNumber: station.lockersNumber,
          emptyLockersNum: station.emptyLockersNum,
        }
      })
    }
  }

  getColumnsForExportFull(stations) {
    if (stations.length > 0) {
      return stations.map((station, index) => {
        return {
          isAlive: station.isAlive,
          stationNumber: station.stationNumber,
          branchName: station.branchName,
          branchAddress: station.branchAddress,
          lockersNumber: station.lockersNumber,
          emptyDeliveryLockersNum: station.emptyDeliveryLockersNum,
          brokenLockersNum: station.brokenLockersNum,
          branchDisplayAddress: station.branchDisplayAddress,
          availabilityHours: station.availabilityHours,
          cityName: station.cityName,
        }
      })
    }
  }

  createFileName() {
    const date = new Date().toLocaleDateString()
    const time = new Date().toLocaleTimeString("en-US", {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
    })
    const filename = `station_${date}_${time}`
    return filename
  }

  render() {
    const { isLoading, stationsFilteredListArr, pageNum, stations } = this.props
    const { pagedList } = this.state
    const fields = {
      stationNumber: "stationNumber",
      branchName: "branchName",
    }
    return (
      <div className="stations-page-wrapper">
        <h1>רשימת עמדות</h1>
        <div className="stations-header">
          <StationsFilterer
            key={stations}
            resetCurrentPage={() => this.resetCurrentPage()}
            stations={stations}
          />
          <JsonToExcel
            title="ייצוא לאקסל חלקי"
            data={this.getColumnsForExportLite(stations)}
            fileName={this.createFileName()}
            btnClassName="stations-export"
          />
          <div className="export">
            <JsonToExcel
              title="ייצוא לאקסל הכל"
              data={this.getColumnsForExportFull(stations)}
              fileName={this.createFileName()}
              btnClassName="stations-export"
            />
          </div>
        </div>
        <div key={pagedList.length}>
          <StationsList
            items={pagedList}
            isLoading={isLoading}
            onItemStatusBtnClick={(item) => this.onItemStatusBtnClick(item)}
          />
          <Pagination
            fullList={stationsFilteredListArr}
            totalPerPage={50}
            onSubListReady={(pagedList, pageNum) => {
              this.props.localSearchStationsSetPage(pageNum)
              this.setState({ pagedList })
            }}
            pageNum={pageNum}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ station, users }) => {
  const { stations, stationsLocalSearch, isLoading } = station
  const { authenticated } = users
  const { stationsFilteredListArr, page } = stationsLocalSearch
  return {
    isLoading,
    stationsFilteredListArr,
    stations,
    pageNum: page,
    authenticated,
  }
}

export default connect(mapStateToProps, {
  fetchStationsByRole,
  showStationLoading,
  clearLocalSearchStations,
  localSearchStationsSetPage,
  selectStation,
  onGetStations,
})(StationsScreen)
