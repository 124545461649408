import axios from "axios"
import api from "../api/BaseApi"

import {
  ON_GET_FAILED_SMS_LOADING,
  ON_GET_FAILED_SMS_SUCCESS,
  ON_GET_FAILED_SMS_FAIL,
  ON_CLEAR_FAILED_SMS,
} from "./types"

export const getFailedSMS = (orderType, fromDateTime) => {
  return (dispatch) => {
    dispatch({
      type: ON_GET_FAILED_SMS_LOADING,
    })

    axios
      .get(api.getFailedSMS(), {
        params: {
          orderType: orderType,
          fromDateTime: fromDateTime.toISOString(),
        },
      })
      .then((res) => {
        dispatch({
          type: ON_GET_FAILED_SMS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_FAILED_SMS_FAIL,
          payload: err,
        })
      })
  }
}

export const clearFailedSMS = () => {
  return {
    type: ON_CLEAR_FAILED_SMS,
  }
}
