import React, { useState } from "react"
import "./style.css"
import Select from "react-select"

const SelectAutocomplete = ({
  placeholder,
  items,
  onChangeHandler,
  selectedVal,
  defaultValue,
}) => {
  return (
    <div className="autocomplete-wrapper">
      <Select
        key={defaultValue}
        defaultValue={selectedVal == "" || !items ? null : defaultValue}
        placeholder={placeholder}
        isClearable={false}
        options={items}
        onChange={onChangeHandler}
      />
    </div>
  )
}

export { SelectAutocomplete }
