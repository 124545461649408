import React, { Component } from "react"
import { connect } from "react-redux"
import Title from "../../common/Title"
import Loader from "../../common/Loader"
//import  {isValidField } from '../../../utils/FieldsValidation';

import {
  onStartLoading,
  onFetchExistOrders,
  setSelectedOrder,
  fetchOrderStatuses,
  clearStationState,
} from "../../../actions"

import "./style.scss"
import OrdersList from "./OrdersList"

class OrdersListScreen extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { authenticated, history } = this.props
    if (!authenticated) {
      history.push("/")
    }
    this.props.onStartLoading()
    this.props.fetchOrderStatuses()
    this.props.onFetchExistOrders()
  }

  componentWillReceiveProps(newProps) {
    const { authenticated, history } = newProps
    if (!authenticated) {
      history.push("/")
    }
  }

  onClickSetOrderToUpdate(orderNumber) {
    const { orderList } = this.props
    this.props.setSelectedOrder(
      orderList.filter((o) => o.orderNumber === orderNumber)[0]
    )
    this.props.history.push("/UpdateOrderScreen")
  }

  onRelocateClick(orderNumber) {
    const { orderList } = this.props
    this.props.setSelectedOrder(
      orderList.filter((o) => o.orderNumber === orderNumber)[0]
    )
    this.props.history.push("/RelocateOrderScreen")
  }

  refreshTable() {
    this.props.onFetchExistOrders()
  }

  render() {
    const { isLoading, ordersStatusesArr } = this.props

    if (isLoading) {
      return (
        <div>
          <Title text="רשימת הזמנות" />
          <Loader show={isLoading} />
        </div>
      )
    }

    return (
      <div>
        <Title text="רשימת הזמנות" />
        {ordersStatusesArr && ordersStatusesArr.length > 0 ? (
          <OrdersList
            onClick={(orderNumber) => this.onClickSetOrderToUpdate(orderNumber)}
            onRelocateClick={(orderNumber) => this.onRelocateClick(orderNumber)}
            refreshTable={() => this.refreshTable()}
          />
        ) : (
          "Loading..."
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ users, orders, orderStatuses }) => {
  const { authenticated, userData } = users
  const { isLoading, orderList } = orders
  const { ordersStatusesArr } = orderStatuses

  return {
    authenticated,
    isLoading,
    orderList,
    userData,
    ordersStatusesArr,
  }
}

export default connect(mapStateToProps, {
  onStartLoading,
  onFetchExistOrders,
  setSelectedOrder,
  fetchOrderStatuses,
  clearStationState,
})(OrdersListScreen)
