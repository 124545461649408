import {
  ON_GET_FAILED_SMS_LOADING,
  ON_GET_FAILED_SMS_SUCCESS,
  ON_GET_FAILED_SMS_FAIL,
  ON_CLEAR_FAILED_SMS,
} from "../actions/types"

const INIT_STATE = {
  failedSMSList: null,
  isLoading: false,
}

const IsraelPostReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_GET_FAILED_SMS_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case ON_GET_FAILED_SMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        failedSMSList: action.payload,
      }
    case ON_GET_FAILED_SMS_FAIL:
      return {
        ...state,
        isLoading: false,
        failedSMSList: null,
      }
    case ON_CLEAR_FAILED_SMS:
      return {
        ...state,
        isLoading: false,
        failedSMSList: null,
      }
    default:
      return state
  }
}

export default IsraelPostReducer
