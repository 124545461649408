import React, { Component } from "react"

import { ButtonRectangle } from "../../common/ButtonRectangle"
import Modal from "../Modal"
import OrderHistoryList from "../OrderHistoryModal/OrderHistotyList"

import "./style.css"

class OrderHistoryModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      order: null,
      orderId: "",
      historyRecords: [],
    }
  }

  render() {
    const { isShow, order, setViewHistory, getFormatedDate, statuses } =
      this.props

    if (!isShow) {
      return <noscript />
    }

    const { errMsg } = this.state

    return (
      <Modal extraClass="cror-modal-wrap">
        <div className="cror-modal-container">
          <div className="cror-modal-inputs-wrapper">
            <div className="cror-modal-title">
              <div className="title"> היסטורית הזמנה </div>
              <div className="order-number"> מס' {order.orderNumber} </div>
            </div>

            {OrderHistoryList(order.historyRecords, statuses, getFormatedDate)}

            <div className="cror-modal-status">
              <ButtonRectangle
                extraClass="pagin-btn"
                onClick={(e) => setViewHistory(false)}
              >
                סגור
              </ButtonRectangle>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default OrderHistoryModal
