import React, { Component } from "react"

import { connect } from "react-redux"

import {
  fetchAllLockersStatuses,
  fetchAllLockersLayout,
  fetchOrderTypes,
} from "../../../actions"
import { getOrderTypeTextFromOrderType } from "../../../utils/OrderStatus"
import Cell from "./Cell"
import "./style.css"

class LockerCells extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mainRef: null,
      calledSmoothScroll: false,
    }
  }

  componentWillMount() {
    //const { stationId } = this.props;
    const stationId = this.props.stationId
    this.props.fetchOrderTypes()
    this.props.fetchAllLockersStatuses(stationId)
    this.props.fetchAllLockersLayout(stationId)
  }

  onCellClick(cellNum) {
    const { lockersData } = this.props

    if (this.props.onCellClick) {
      this.props.onCellClick(cellNum, lockersData[cellNum - 1])
    }
  }
  maxHorizontal(cellsArr) {
    if (cellsArr == null) {
      return <div>אין מידע על העמדה</div>
    }
    if (cellsArr.length < 1) return

    let maxHorizontal = cellsArr
      .map((arr) => arr.length)
      .reduce((max, curr) => Math.max(max, curr))

    return maxHorizontal
  }

  renderCells(
    cellsArr,
    blinkers,
    greens,
    reds,
    blacks,
    greys,
    blues,
    yellows,
    errLockers
  ) {
    const { lockersData, hideInfo, orderTypesArr } = this.props

    const res = []
    if (cellsArr == null) {
      return <div>אין מידע על העמדה</div>
    }
    if (cellsArr.length < 1) return

    // CALCULATING THE REPUDING FROM THE RIGHT SIDE.. :
    let maxHorizontal = this.maxHorizontal(cellsArr)
    cellsArr.push(new Array(0)) // RIPUD MILEMATA..!

    for (let j = 0; j < maxHorizontal; j++) {
      const iterationRes = []

      let cellNum = cellsArr[0][j]
      let currCellSize = 0

      for (let i = 0; i < cellsArr.length; i++) {
        let currCell = cellsArr[i][j]

        if (cellNum !== currCell) {
          const orderData = lockersData[cellNum - 1]
            ? lockersData[cellNum - 1].order
            : null

          if (orderData) {
            orderData.orderTypeName = getOrderTypeTextFromOrderType(
              orderTypesArr,
              orderData.orderType
            )
          }

          iterationRes.push(
            <Cell
              num={cellNum}
              size={currCellSize}
              blink={blinkers[`${cellNum}`]}
              red={reds[`${cellNum}`]}
              green={greens[`${cellNum}`]}
              black={blacks[`${cellNum}`]}
              grey={greys[`${cellNum}`]}
              blue={blues[`${cellNum}`]}
              yellow={yellows[`${cellNum}`]}
              errLocker={errLockers[`${cellNum}`]}
              order={orderData}
              hideInfo={hideInfo}
              onClick={this.onCellClick.bind(this)}
              key={`${i} ${j}`}
              onRefReady={this.handleCellRef.bind(this)}
            />
          )

          cellNum = currCell
          currCellSize = 1
        } else {
          currCellSize++
        }
      }

      res.push(
        <div className="lockcell-col-wrap" key={res.length}>
          {iterationRes}
        </div>
      )
    }

    return res
  }

  handleCellRef(cellNum, ref) {
    const { mainRef } = this.state
    const { blinkers, showMinified } = this.props

    if (!showMinified) return

    const { calledSmoothScroll } = this.state

    if (calledSmoothScroll) {
      return
    }

    // JUMPINT TO THE BLINKING ELEMENT..
    if (blinkers[`${cellNum}`] && mainRef && ref) {
      this.setState({ calledSmoothScroll: true })

      const refRect = ref.getBoundingClientRect()
      const mainRect = mainRef.getBoundingClientRect()

      const scrollLeftBy = refRect.x - mainRect.x - 10

      mainRef.scrollLeft += scrollLeftBy
      // TODO - FIX WHEN THERE'S TIME
      // setTimeout(() => this.scrollSmoothly(mainRef, scrollLeftBy), 1000);
    }
  }

  scrollSmoothly(mainRef, scrollLeftBy, iteration = 0) {
    if (iteration === 100) {
      return
    }

    // if (iteration === 1) {
    //     mainRef.scrollLeft += scrollLeftBy / 2;
    // }

    // mainRef.scrollLeft += scrollLeftBy / 200;
    mainRef.scrollLeft += scrollLeftBy / 100

    setTimeout(
      () => this.scrollSmoothly(mainRef, scrollLeftBy, iteration + 1),
      scrollLeftBy / 50
    )
  }

  onMainRefReady(ref) {
    const { mainRef } = this.state

    if (!mainRef) {
      this.setState({ mainRef: ref })
    }
  }

  render() {
    const { lockersLayout, showMinified } = this.props

    const {
      greens,
      reds,
      blacks,
      greys,
      blues,
      yellows,
      blinkers = [],
      errLockers = [],
      extraClass,
    } = this.props

    const lockCellClassName = `lockcell-wrapper ${extraClass} ${
      this.maxHorizontal(lockersLayout) < 7 && "centered"
    }`

    return (
      <div
        className={lockCellClassName}
        ref={(ref) => this.onMainRefReady(ref)}
      >
        {this.renderCells(
          lockersLayout,
          blinkers,
          greens,
          reds,
          blacks,
          greys,
          blues,
          yellows,
          errLockers
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ lockersCells, orderTypes }) => {
  const { lockersData, lockersLayout } = lockersCells
  const { orderTypesArr } = orderTypes
  const blacks = {}
  const reds = {}
  const greens = {}
  const greys = {}
  const blues = {}
  const yellows = {}

  lockersData.map(({ lockerStatus, lockerName, disabled, pickUpDisabled }) => {
    if (lockerStatus === 0) blacks[parseInt(lockerName)] = 1
    if (lockerStatus === 1) reds[parseInt(lockerName)] = 1
    if (lockerStatus === 2) greens[parseInt(lockerName)] = 1
    if (disabled) greys[parseInt(lockerName)] = 1
    if (pickUpDisabled) yellows[parseInt(lockerName)] = 1

    return null
  })

  return {
    lockersData,
    lockersLayout,
    orderTypesArr,
    blacks,
    reds,
    greens,
    greys,
    blues,
    yellows,
  }
}

export default connect(mapStateToProps, {
  fetchAllLockersStatuses,
  fetchAllLockersLayout,
  fetchOrderTypes,
})(LockerCells)
