import React from "react"

import Header from "../../../common/List/Header"
import StationItem from "./StationItem"
import { Spinner } from "../../../common/Spinner"
import { H1 } from "../../../common/H1"
import "./style.css"

const stationHeaders = [
  "מחובר",
  "מזהה",
  "שם סניף",
  "כתובת",
  "מספר תאים",
  "תאים ריקים",
  "תאים שבורים",
  "תיאור המקום",
  "שעות פעילות",
  "עיר",
  "",
]
const stationItemMap = [
  "isAlive",
  "stationNumber",
  "branchName",
  "branchAddress",
  "lockersNumber",
  "emptyDeliveryLockersNum",
  "brokenLockersNum",
  "branchDisplayAddress",
  "availabilityHours",
  "cityName",
  "edit",
]
const stationGravity = { 0: 0.5, 1: 0.5, 2: 2, 3: 2, 4: 0.5, 7: 2 }

const StationsList = ({
  extraClass,
  items,
  onItemStatusBtnClick,
  onSendSmsBtnClick,
  onCellsBtnClick,
  onLockersHistoryBtnClick,
  isError,
  isLoading,
}) => {
  return (
    <div className={`stations-list list-wrapper ${extraClass}`}>
      <Header titles={stationHeaders} gravity={stationGravity} />
      <div className="list-items-wrapper">
        {renderItems(
          items,
          stationGravity,
          stationItemMap,
          onItemStatusBtnClick,
          onSendSmsBtnClick,
          onCellsBtnClick,
          onLockersHistoryBtnClick,
          isError,
          isLoading
        )}
      </div>
    </div>
  )
}

const renderItems = (
  items,
  stationGravity,
  stationItemMap,
  onItemStatusBtnClick,
  isError,
  isLoading
) => {
  if (isError) {
    return (
      <H1 className="not-valid-search" title="אנא בדוק את הערך של שדה חיפוש" />
    )
  }
  if (isLoading) {
    return (
      <div className="station-editor-spinner-wrapper">
        <Spinner zoom={1} />
      </div>
    )
  }
  if (!items || items.length === 0) {
    return <H1 className="no-list-items-text" title="אין רשומות" />
  }

  return items.map((itm, index) => {
    return (
      <StationItem
        key={index}
        item={itm}
        itemMap={stationItemMap}
        gravity={stationGravity}
        isLast={index === items.length - 1}
        onStatusBtnClick={onItemStatusBtnClick}
      />
    )
  })
}

export default StationsList
