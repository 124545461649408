import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import * as XLSX from "xlsx/xlsx.mjs"
import { Button } from "../../common/Button"
import { getFailedSMS, fetchOrderTypes } from "../../../actions"
import question from "../../../assets/images/question.png"
import "./style.scss"

const FailedSMSScreen = (props) => {
  const [isShowFailedSMSList, setShowFailedSMSList] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  const itemsPerPage = 20
  const totalItems = props.failedSMSList ? props.failedSMSList.length : 0
  const totalPages = Math.ceil(totalItems / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const currentItems = props.failedSMSList
    ? props.failedSMSList.slice(startIndex, endIndex)
    : []

  const hebrewNotificationStatusList = {
    "-1": "לא ידוע",
    0: "מחכה להשלח",
    1: "נשלח",
    2: "שגיאה בשליחה",
    3: "נשלח בהצלחה",
    4: "אחר",
    5: "טלפון כשר",
  }

  const statusExplanation = {
    "-1": "סיבה לרא ידועה",
    0: "עדיין לא נשלח",
    1: "נשלח, אין חיווי להגעה",
    2: "שגיאה בשליחה",
    3: "יש חיווי שהגיע למכשיר הסלולרי",
    4: "אחר",
    5: "טלפון כשר",
  }

  useEffect(() => {
    props.fetchOrderTypes()
  }, [])

  useEffect(() => {
    if (props.orderTypesArr?.length > 0) {
      const orderType = props.orderTypesArr.indexOf(props.userData.role)
      const dateTime = getCurrentDateTimeMinusWeeks(2)
      props.getFailedSMS(orderType, dateTime)
    }
  }, [props.orderTypesArr])

  useEffect(() => {
    if (props.failedSMSList?.length > 0) {
      setShowFailedSMSList(true)
    }
  }, [props.failedSMSList])

  const getValueById = (object, id) => {
    return object[id] || id
  }

  function convertToIsraelDateFormat(inputDate) {
    const date = new Date(inputDate)
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = String(date.getFullYear())
    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`
    return formattedDate
  }

  const exportToExcel = () => {
    const header = ["הודעה", "מספר טלפון", "מספר חבילה", "סטטוס", "תאריך"]
    const data = props.failedSMSList.map((item) => [
      item.message,
      item.phoneNumber,
      item.packageNumber,
      getValueById(hebrewNotificationStatusList, item.notificationStatus),
      convertToIsraelDateFormat(item.createdDate),
    ])

    const ws = XLSX.utils.aoa_to_sheet([header, ...data])
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "Failed_SMS_List")
    const currentDateTime = new Date().toLocaleDateString("he-IL")
    const fileName = `failed_sms_list_${currentDateTime}.xlsx`

    XLSX.writeFile(wb, fileName)
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  return (
    <div className="failedSmsScreen">
      <h1>רשימת הודעות SMS שנכשלו</h1>
      {isShowFailedSMSList ? (
        <div>
          <Button extraClass="export-btn" onClick={() => exportToExcel()}>
            ייצוא לאקסל
          </Button>
          <table className="sms-table">
            <thead>
              <tr>
                <th>תאריך</th>
                <th>סטטוס</th>
                <th>מספר חבילה</th>
                <th>מספר טלפון</th>
                <th>הודעה</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td>{convertToIsraelDateFormat(item.createdDate)}</td>
                  <td>
                    <div className="status">
                      {getValueById(
                        hebrewNotificationStatusList,
                        item.notificationStatus
                      )}
                      <img src={question} alt="question mark"></img>
                      <div className="tooltip-bubble">
                        {statusExplanation[item.notificationStatus]}{" "}
                        <div className="tooltip-arrow"></div>
                      </div>
                    </div>
                  </td>
                  <td>{item.packageNumber}</td>
                  <td>{item.phoneNumber}</td>
                  <td>{item.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            {currentPage > 1 && <button onClick={handlePrevPage}>קודם</button>}
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                className={currentPage === index + 1 ? "active" : ""}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            {currentPage < totalPages && (
              <button onClick={handleNextPage}>הבא</button>
            )}
          </div>
        </div>
      ) : (
        <h2>אין רשומות</h2>
      )}
    </div>
  )
}

const getCurrentDateTimeMinusWeeks = (weeks) => {
  const currentDate = new Date()
  const newDate = new Date(currentDate)
  newDate.setDate(newDate.getDate() - weeks * 7)
  return newDate
}

const mapStateToProps = ({ users, orderTypes, failedSMS }) => {
  const { userData } = users
  const { orderTypesArr } = orderTypes
  const { failedSMSList } = failedSMS

  return { userData, orderTypesArr, failedSMSList }
}

export default connect(mapStateToProps, { getFailedSMS, fetchOrderTypes })(
  FailedSMSScreen
)
