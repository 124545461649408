import axios from "axios"
import api from "../api/BaseApi"

export const saveResultInBgServiceKeyValue = (key, value) => {
  axios
    .post(api.createOrUpdateKeyValuePair, { key, value })
    .then((res) => {})
    .catch((err) => {})
}

export const readResultFromBgServiceKeyValue = (key) => {
  return new Promise((resolve, reject) => {
    axios
      .post(api.getKeyValuePairByKey, { key })
      .then((res) => {
        resolve(res.data.value)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const ENABLE_BG_SYNC = false
