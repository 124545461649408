import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from '../../../store';

import './style.css';

class Modal extends Component {

    componentDidMount() {
        this.modalTarget = document.createElement('div');
        this.modalTarget.className = `modal ${this.props.extraClass}`;
        document.body.appendChild(this.modalTarget);
        this._render();
    }

    componentWillUpdate() {
        this._render();
    }

    componentWillUnmount() {
        ReactDOM.unmountComponentAtNode(this.modalTarget);
        document.body.removeChild(this.modalTarget);
    }

    _render() {
        ReactDOM.render(
            <Provider store={store}>
                <div>{this.props.children}</div>
            </Provider>,
            this.modalTarget
        );
    }

    render() {
        // NOT RENDERING WORKAROUND..!:
        try {
            this._render();
        }
        catch (e) { }

        return <noscript />;
    }
}

export default Modal;
