import React from "react"

import "./style.scss"

const InputDatePicker = ({
  labelText,
  name,
  date,
  onChange,
  placeholder,
  disabled = false,
}) => {
  const formatedDate = date ? date.substring(0, 10) : ""
  return (
    <div className={"date-picker-wrapper"}>
      <div className="date-picker-label">{placeholder}</div>
      <input
        type="date"
        disabled={disabled}
        className={"date-picker date-picker-input"}
        name={name}
        value={formatedDate}
        placeholder={placeholder}
        alt={placeholder}
        onChange={(e) => onChange(name, e)}
      />
    </div>
  )
}

export { InputDatePicker }
