import React from "react"
import { useFilters, usePagination, useSortBy, useTable } from "react-table"
import { useExportData } from "react-table-plugins"
import { Button as ButtonReact, Input } from "reactstrap" //CustomInput
import * as XLSX from "xlsx/xlsx.mjs"
import { DefaultColumnFilter } from "../../../filters/DefaultColumnFilter"
import { Filter } from "../../../filters/Filter"
import { Button } from "../Button"
import "./style.scss"

const TableContainer = ({ columns, data, onClick, refreshTable }) => {
  const uTable = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: 50 },
      getExportFileBlob,
    },
    useFilters,
    useSortBy,
    usePagination,
    useExportData
  )

  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    exportData,
  } = uTable

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value))
  }

  function getExportFileBlob({ columns, data, fileType, fileName }) {
    if (fileType === "xlsx") {
      const header = columns.map((c) => c.exportValue)
      const compatibleData = data.map((row) => {
        const obj = {}
        header.forEach((col, index) => {
          obj[col] = row[index]
        })
        return obj
      })

      let wb = XLSX.utils.book_new()
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      })
      XLSX.utils.book_append_sheet(wb, ws1, "Orders List")
      XLSX.writeFile(wb, "OrdersExport.xlsx")
      // Returning false as downloading of file is already taken care of
      return false
    }
    return false
  }

  return (
    <>
      <div className="list-counter">
        {`נמצאו ${uTable.filteredRows.length} רשומות`}
      </div>
      <Button
        extraClass="export-btn"
        onClick={() => {
          exportData("xlsx", false)
        }}
      >
        ייצוא לאקסל
      </Button>
      <Button
        extraClass="refresh-btn"
        onClick={() => {
          refreshTable()
        }}
      >
        רענן טבלה
      </Button>
      <div className="table-container">
        <div className="pagination">
          <div md={3}>
            <ButtonReact
              color="primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </ButtonReact>
            <ButtonReact
              color="primary"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </ButtonReact>
          </div>

          <div md={2} style={{ marginTop: 7 }}>
            דף{" "}
            <strong>
              {" "}
              {pageIndex + 1} מ-{pageOptions.length}{" "}
            </strong>
          </div>

          <div md={3}>
            <ButtonReact
              color="primary"
              onClick={nextPage}
              disabled={!canNextPage}
            >
              {">"}
            </ButtonReact>
            <ButtonReact
              color="primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </ButtonReact>

            <div md={2}>
              <Input type="select" value={pageSize} onChange={onChangeInSelect}>
                {">"}
                {[50, 100, 150, 200].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    הצג {pageSize}
                  </option>
                ))}
              </Input>
            </div>
          </div>
        </div>
        <table bordered hover {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                    </span>
                    <div>{generateSortingIndicator(column)}</div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {page.map((row) => {
              prepareRow(row)
              return (
                <tr key={row.id}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default TableContainer
