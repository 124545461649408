import React, { Component } from "react"
import { connect } from "react-redux"
import InputField from "../../common/InputField"
import { Button } from "../../common/Button"
//import Loader from "../../common/Loader"
import { isValidField } from "../../../utils/FieldsValidation"
import Message from "../../common/Message"
import Title from "../../common/Title"
import {
  updateUserPassword,
  clearUserState,
  clearRequestData,
} from "../../../actions"
import eyeIcon from "../../../assets/images/eye.svg"

import "./style.scss"

class ChangePasswordScreen extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      newPassword: "",
      confirmPassword: "",
      isMatchError: false,
      inputType: "password",
      isShowPassword: false,
      isShowConfirmPassword: false,
      validField: {
        newPassword: {
          type: "password",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
        confirmPassword: {
          type: "passwordConfirm",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
      },
    }

    this.state = this.INIT_STATE
  }

  componentDidMount() {
    const { authenticated, history } = this.props
    if (!authenticated) {
      history.push("/")
    }
    this.state = this.INIT_STATE
    this.onInputFocus("newPassword")
    this.onInputFocus("conformPassword")
  }

  componentDidUpdate(prevProps) {
    const { authenticated, history } = prevProps
    if (!authenticated) {
      history.push("/")
    }
  }

  componentWillUnmount() {}

  validateInputValue(inputName, value, equalToValue) {
    let { validField } = this.state
    let field = validField[inputName]
    if (!field) return

    field.isChecked = true

    if ("passwordConfirm" === field.type) {
      field.compareMinValue = equalToValue
    }

    field.isValid = isValidField(
      value,
      field.type,
      field.isRequired,
      field.compareMinValue,
      field.compareMaxValue
    )
    validField[inputName] = field

    console.log(
      inputName + ": " + value + (field.isValid ? " valid " : " not valid ")
    )

    this.setState({
      ...this.state,
      validField,
    })
  }

  onInputFocus(inputName) {
    let { validField } = this.state
    let field = validField[inputName]
    if (!field) return

    field.isChecked = false
    field.isValid = false
    validField[inputName] = field

    this.setState({
      ...this.state,
      validField,
    })
  }

  onChangePassBtn() {
    const { validField, newPassword, confirmPassword } = this.state
    this.validateInputValue("newPassword", newPassword)
    this.validateInputValue("confirmPassword", confirmPassword, newPassword)
    const isValid =
      validField.newPassword.isChecked &&
      validField.newPassword.isValid &&
      validField.confirmPassword.isChecked &&
      validField.confirmPassword.isValid
    if (newPassword != confirmPassword) {
      this.setState({ isMatchError: true })
      return
    }
    if (!isValid) {
      return
    }
    const newUserData = {
      password: newPassword,
      id: this.props.userData.userId,
    }
    this.props.updateUserPassword(newUserData)
  }

  togglePassword = () => {
    this.setState({ isShowPassword: !this.state.isShowPassword })
  }

  toggleConfirmPassword = () => {
    this.setState({ isShowConfirmPassword: !this.state.isShowConfirmPassword })
  }

  onMsgMatchClickOk(e) {
    this.setState({ isMatchError: false })
  }
  onMsgSuucessClickOk(e) {
    this.props.clearUserState()
    this.props.history.push("/")
  }

  render() {
    const { validField, isMatchError, isShowPassword, isShowConfirmPassword } =
      this.state
    const { createUpdateRequestError, createUpdateRequsetSuccess } = this.props

    return (
      <div className="changePassword">
        <Title text="שינוי סיסמה" />

        <div className="input-wrapper">
          <img
            className="eye-icon"
            src={eyeIcon}
            alt="show password icon"
            onClick={() => this.togglePassword()}
          />
          <InputField
            isFocused={"true"}
            show={"true"}
            value={this.state.newPassword}
            placeholder="סיסמא חדשה"
            mandatory={true}
            showValidation={validField.newPassword.isChecked}
            showValidationOk={validField.newPassword.isValid}
            showValidationError={!validField.newPassword.isValid}
            validationErrorMessage="מינימום 8 תווים, לפחות אות גדולה ואות קטנה אחת באנגלית, לפחות ספרה אחת, לפחות סימן מיוחד אחד  !@#$%^&*"
            onChange={(e) => {
              this.validateInputValue("newPassword", e.target.value)
              this.validateInputValue(
                "confirmPassword",
                this.state.confirmPassword,
                e.target.value
              )
              this.setState({ newPassword: e.target.value })
            }}
            type={isShowPassword ? "text" : "password"}
            maxLength={20}
          />
        </div>
        <div className="input-wrapper">
          <img
            className="eye-icon"
            src={eyeIcon}
            alt="show confirm password icon"
            onClick={() => this.toggleConfirmPassword()}
          />
          <InputField
            isFocused={"false"}
            show={"true"}
            value={this.state.confirmPassword}
            placeholder="אימות סיסמא"
            mandatory={true}
            showValidation={validField.confirmPassword.isChecked}
            showValidationOk={validField.confirmPassword.isValid}
            showValidationError={!validField.confirmPassword.isValid}
            validationErrorMessage="לא תואם לסיסמא"
            onChange={(e) => {
              this.validateInputValue(
                "confirmPassword",
                e.target.value,
                this.state.newPassword
              )
              this.setState({ confirmPassword: e.target.value })
            }}
            type={isShowConfirmPassword ? "text" : "password"}
            maxLength={20}
          />
        </div>

        <div className="buttons-wrapper">
          <Button onClick={(e) => this.onChangePassBtn()}>שינוי סיסמה</Button>
        </div>

        <Message
          show={isMatchError}
          isError={true}
          successText=""
          errorText="סיסמאות לא תואמות"
          onClick={(e) => this.onMsgMatchClickOk(e)}
        />
        <Message
          show={createUpdateRequsetSuccess}
          isError={false}
          successText="סיסמתך שונתה בהצלחה"
          errorText=""
          onClick={(e) => this.onMsgSuucessClickOk(e)}
        />
        <Message
          show={createUpdateRequestError}
          isError={true}
          successText=""
          errorText="שגיאה, אנא נסה שנית או פנה לשירות לקוחות"
          onClick={(e) => this.props.clearRequestData()}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ users }) => {
  const {
    authenticated,
    userData,
    isLoading,
    showMessage,
    createUpdateRequestError,
    createUpdateRequsetSuccess,
  } = users

  return {
    authenticated,
    userData,
    isLoading,
    showMessage,
    createUpdateRequestError,
    createUpdateRequsetSuccess,
  }
}

export default connect(mapStateToProps, {
  updateUserPassword,
  clearUserState,
  clearRequestData,
})(ChangePasswordScreen)
