import React, { Component } from "react"
import { connect } from "react-redux"
import Title from "../../common/Title"
import Loader from "../../common/Loader"
//import { Button } from "../../common"

//import  {isValidField } from '../../../utils/FieldsValidation';

import {
  onStartLoading,
  searchOrders,
  setOrdersSearch,
  setSelectedOrder,
  fetchOrderStatuses,
  fetchOrderTypes,
  fetchStationsByRole,
  clearStationState,
} from "../../../actions"

import { fetchIfEmpty } from "../../../utils"
import OrdersList from "./OrdersList"
import OrdersSearchFilterer from "../../common/OrdersSearchFilterer"
import "./style.scss"

class OrdersSearchScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      searchInterval: null,
    }
  }

  componentDidMount() {
    const { authenticated, history } = this.props
    if (!authenticated) {
      history.push("/")
    }
    this.props.onStartLoading()

    fetchIfEmpty(this.props.stations, this.props.fetchStationsByRole)
    fetchIfEmpty(this.props.orderTypesArr, this.props.fetchOrderTypes)
    fetchIfEmpty(this.props.ordersStatusesArr, this.props.fetchOrderStatuses)

    this.props.searchOrders(this.props.searchData)
  }
  componentWillUnmount() {
    const { searchInterval } = this.state
    if (searchInterval) {
      clearInterval(searchInterval)
      this.setState({ searchInterval: null })
    }
  }
  componentWillReceiveProps(newProps) {
    const { authenticated, history } = newProps
    if (!authenticated) {
      history.push("/")
    }
  }

  handleScroll = (e) => {
    return
    const { searchData } = this.props
    const { nextPageKey } = searchData
    if (nextPageKey == null) {
      return
    }
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - 2 <= e.target.clientHeight

    if (bottom) {
      this.searchOrdersClick()
    }
  }

  onClickSetOrderToUpdate(orderNumber) {
    const { orderList } = this.props
    this.props.setSelectedOrder(
      orderList.filter((o) => o.orderNumber === orderNumber)[0]
    )
    this.props.history.push("/UpdateOrderScreen")
  }

  onRelocateClick(orderNumber) {
    const { orderList } = this.props
    this.props.setSelectedOrder(
      orderList.filter((o) => o.orderNumber === orderNumber)[0]
    )
    this.props.history.push("/RelocateOrderScreen")
  }

  searchOrdersClick() {
    let searchData = this.props.searchData

    this.props.onStartLoading()
    this.props.searchOrders(searchData)

    //console.log("call search page: " + searchData.page + " nextPageKey:" + searchData.nextPageKey )

    const { searchInterval } = this.state
    if (!searchInterval) {
      //SET INTERVAL
      const searchInterval = setInterval(() => {
        //console.log("searchInterval    page:" + this.props.searchData.page)

        if (!this.props.isLoadingSearch) {
          //console.log("isStopSearching: " + this.props.isStopSearching)
          if (this.state.searchInterval && this.props.isStopSearching) {
            //console.log("searchInterval    stop!!!!")
            clearInterval(this.state.searchInterval)
            this.setState({ searchInterval: null })

            return
          }

          let searchData = this.props.searchData
          //searchData.page = searchData.page + 1

          this.props.onStartLoading()
          //this.props.setOrdersSearch(searchData)
          this.props.searchOrders(searchData)
          //console.log(  "call search page: " +  searchData.page +" nextPageKey:" +searchData.nextPageKey)
        }
      }, 3000)
      this.setState({ searchInterval })
    }
  }

  onSearchFilterChanged(searchFilters) {
    this.props.setOrdersSearch(searchFilters)
  }

  filterEnterAndSubmit(e) {
    if (e.key === "Enter") {
      this.searchOrdersClick()
    }
  }

  render() {
    const {
      isLoadingSearch,
      orderList,
      //searchData
    } = this.props
    //const { nextPageKey } = searchData

    return (
      <div className="search-wrapper">
        <Title text="חיפוש הזמנות" />

        {!orderList || orderList.length < 1 ? (
          <Loader show={true} />
        ) : (
          <>
            <OrdersSearchFilterer
              onSearchFilterChanged={(searchFilters) =>
                this.onSearchFilterChanged(searchFilters)
              }
              filterEnterAndSubmit={(e) => this.filterEnterAndSubmit(e)}
              searchOrdersClick={(e) => this.searchOrdersClick()}
            />

            <div
              className="scrolled-wrapper"
              //onScroll={(e) => this.handleScroll(e)}
            >
              <OrdersList
                onClick={(orderNumber) =>
                  this.onClickSetOrderToUpdate(orderNumber)
                }
                onRelocateClick={(orderNumber) =>
                  this.onRelocateClick(orderNumber)
                }
                //refreshTable={() => this.refreshTable()}
                orderList={orderList}
              />

              {isLoadingSearch && <Loader show={true} />}
            </div>

            {/*
            !isLoadingSearch && nextPageKey != null && (
              <div className="search-btn-wrapper">
                <Button
                  extraClass={isLoadingSearch ? "disabled" : "search-btn"}
                  onClick={(e) => this.searchOrdersClick()}
                  tabIndex="0"
                  disabled={isLoadingSearch}
                >
                  יש עוד...
                </Button>
              </div>
            )
              */}
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({
  users,
  searchOrders,
  orderStatuses,
  orderTypes,
  station,
}) => {
  const { authenticated, userData } = users
  const { isLoadingSearch, orderList, searchData, isStopSearching } =
    searchOrders
  const {
    ordersStatusesArr, //ordersStatuses_SelectList
  } = orderStatuses
  const {
    orderTypesArr, //orderTypes_SelectList
  } = orderTypes
  //const { stationsItemSelectList } = stations
  const { stations } = station

  return {
    authenticated,
    isLoadingSearch,
    isStopSearching,
    orderList,
    userData,
    ordersStatusesArr,
    orderTypesArr,
    searchData,
    stations,
  }
}

export default connect(mapStateToProps, {
  onStartLoading,
  searchOrders,
  setOrdersSearch,
  setSelectedOrder,
  fetchOrderStatuses,
  fetchOrderTypes,
  fetchStationsByRole,
  clearStationState,
})(OrdersSearchScreen)
