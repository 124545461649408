import React, { useState } from "react"
import Select from "react-select"
import "./changeStationModal.scss"

const ChangeStationModal = ({
  stations,
  currentStationNumber,
  onSaveClick,
  onCloseClick,
}) => {
  const [stationNumber, setStationNumber] = useState(currentStationNumber)

  return (
    <div className="stations-modal">
      <div>
        <h2>בחר עמדה</h2>

        <div className="current-station">
          <span>עמדה נוכחית: {currentStationNumber}</span>
        </div>
        <Select
          value={stations.find((item) => item.value === stationNumber)}
          className="station-selector"
          placeholder="עמדה"
          options={stations}
          onChange={(selectedStation) =>
            setStationNumber(selectedStation.value)
          }
        />
        <div className="btn-holder">
          <button onClick={() => onSaveClick(stationNumber)}>שמור</button>
          <button onClick={() => onCloseClick()}>סגור</button>
        </div>
      </div>
    </div>
  )
}

export default ChangeStationModal
