import React, { useMemo, useState } from "react"
import { connect } from "react-redux"
import TableContainer from "../../../common/TableContainer"
import { Container } from "reactstrap"
import { setEmtyIfNull } from "../../../../utils/getIfEmpty"
import { ButtonRectangle } from "../../../common/ButtonRectangle"
import { SelectColumnFilter } from "../../../../filters/SelectColumnFilter"
import "./style.scss"

const UsersList = (props) => {
  let [data] = useState([])
  const {
    usersListArr,
    onClickDelete,
    onClickResetPass,
    onClickResetBOPass,
    onClickSetAsManager,
    onClickRemoveFromManagerList,
    refreshTable,
    orderTypeGroup,
  } = props

  const formateUser = (user) => {
    const { firstName, lastName, mobilePhone, id, isBOUser, boId } = user

    return {
      id: id,
      mobilePhone: setEmtyIfNull(mobilePhone),
      firstName: setEmtyIfNull(firstName),
      lastName: setEmtyIfNull(lastName),
      isBOUser: isBOUser === true ? "מנהל" : "נהג",
      boId: boId,
    }
  }

  const formattedList = usersListArr
    ? usersListArr.map((order) => formateUser(order))
    : []

  data = useMemo(() => formattedList, [usersListArr])

  const columns = useMemo(() => {
    const result = [
      {
        Header: "מנהל / נהג",
        accessor: "isBOUser",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: (props) => {
          const { original } = props.cell.row
          if (orderTypeGroup === "6") {
            return (
              <i
                class={
                  props.cell.row.original.isBOUser === "מנהל"
                    ? "fa fa-briefcase blue"
                    : "fa fa-user green"
                }
                aria-hidden="true"
              ></i>
            )
          } else {
            return (
              <i
                class={
                  props.cell.row.original.isBOUser === "מנהל"
                    ? "fa fa-briefcase blue"
                    : "fa fa-truck orange"
                }
                aria-hidden="true"
              ></i>
            )
          }
        },
      },
      {
        Header: "שם פרטי",
        accessor: "firstName",
      },
      {
        Header: "שם משפחה",
        accessor: "lastName",
      },
      {
        Header: "נייד",
        accessor: "mobilePhone",
      },
      {
        Header: () => null,
        id: "pass",
        Cell: (props) => (
          <ButtonRectangle
            extraClass="btn-reset-pass"
            onClick={() => onClickResetPass(props.cell.row.original.id)}
          >
            איפוס סיסמת בלדר
          </ButtonRectangle>
        ),
      },
      {
        Header: () => null,
        id: "setBOUser",
        Cell: (props) => (
          <ButtonRectangle
            extraClass="btn-reset-pass"
            onClick={() =>
              props.cell.row.original.isBOUser === "מנהל"
                ? onClickRemoveFromManagerList(props.cell.row.original.id)
                : onClickSetAsManager(props.cell.row.original.id)
            }
          >
            {props.cell.row.original.isBOUser === "מנהל"
              ? "הסר ממנהלים"
              : "להפוך למנהל"}
          </ButtonRectangle>
        ),
      },
      {
        Header: () => null,
        id: "resetBOUserPassword",
        Cell: (props) =>
          props.cell.row.original.isBOUser === "מנהל" && (
            <ButtonRectangle
              extraClass="btn-reset-pass"
              onClick={() => onClickResetBOPass(props.cell.row.original.boId)}
            >
              {"איפוס סיסמת מנהל"}
            </ButtonRectangle>
          ),
      },
      {
        Header: () => null,
        id: "key",
        Cell: (props) => (
          <ButtonRectangle
            extraClass="btn-delete"
            onClick={() => onClickDelete(props.cell.row.original.id)}
          >
            מחיקת בלדר
          </ButtonRectangle>
        ),
      },
    ]
    return result
  }, [])

  return (
    <div className="user-list-wrapper">
      <Container className="user-list">
        <TableContainer
          columns={columns}
          data={data}
          refreshTable={() => refreshTable()}
        />
      </Container>
    </div>
  )
}

const mapStateToProps = ({ users }) => {
  const { usersListArr } = users

  return {
    usersListArr,
  }
}

export default connect(mapStateToProps)(UsersList)
