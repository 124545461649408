import React, { Component } from "react"
import { connect } from "react-redux"
import {
  onUpdateMessage,
  onGetStations,
  closeMessage,
  clearLockersStatuses,
} from "../../../actions"
import LockerCells from "../../common/LockerCells"
import { ButtonRectangle } from "../../common/ButtonRectangle"
import "./style.scss"

class StationStatusScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentWillUnmount() {
    this.props.clearLockersStatuses()
  }

  componentDidMount() {
    if (!this.props.authenticated) {
      this.props.history.push("/")
    }
  }

  render() {
    let {} = this.state
    const { selectedStationId } = this.props

    return (
      <div className="station-status-wrapper">
        <div className="btn-back-wrapper">
          <ButtonRectangle
            extraClass="btn-back"
            onClick={() => this.props.history.goBack()}
          >
            חזור
          </ButtonRectangle>
        </div>

        <LockerCells stationId={selectedStationId} />
      </div>
    )
  }
}

const mapStateToProps = ({ users, station }) => {
  const { authenticated } = users
  const { stationsAll, showMessage, isError, selectedStationId } = station

  return {
    authenticated,
    stationsAll,
    showMessage,
    isError,
    selectedStationId,
  }
}

export default connect(mapStateToProps, {
  onUpdateMessage,
  onGetStations,
  closeMessage,
  clearLockersStatuses,
})(StationStatusScreen)
