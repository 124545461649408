export const getFormatedDate = (dateStr) => {
  if (dateStr == null || dateStr === undefined || dateStr === "") {
    return ""
  }

  const dateWithTime = dateStr.split("T")
  let dateObj
  if (dateWithTime.length > 1) {
    dateObj = new Date(dateStr)
  } else {
    const dateString = dateStr + "T00:00:00.000+00:00"
    dateObj = new Date(dateString)
  }

  const result = dateObj
    .toLocaleString("he-IL", { timeZone: "Israel" })
    .replace(/\./g, "/")

  if (dateObj.getFullYear() === 1) {
    return ""
  }

  return result
}

export const getFormatedDateOnly = (dateStr) => {
  if (dateStr == null) return ""
  const dateObj = new Date(dateStr + "+00:00")
  const result = dateObj
    .toLocaleDateString("he-IL", { timeZone: "Israel" })
    .replace(/\./g, "/")

  if (dateObj.getFullYear() === 1) {
    return "בתהליך"
  }

  return result
}

export const getShortDate = (dateStr) => {
  if (dateStr === null || dateStr === undefined || dateStr === "") return ""
  return dateStr.substr(0, 10)
}

export const getTimeSpanFormated = (timeSpan) => {
  if (timeSpan === null || timeSpan === undefined || timeSpan === "") return ""
  let list = []
  list = timeSpan.split(".")

  let days = 0
  let hours = 0
  let minutes = 0

  if (list.length === 3) {
    days = list[0]
    list = list[1].split(":")
    hours = list.length > 0 ? list[0] : 0
    minutes = list.length > 1 ? list[1] : 0
  } else if (list.length === 2) {
    list = list[0].split(":")
    hours = list.length > 0 ? list[0] : 0
    minutes = list.length > 1 ? list[1] : 0
  }

  let beforeText = ""
  if (days > 0) {
    beforeText += days + " ימים "
  }
  if (hours > 0) {
    beforeText += hours - 0 + " שעות "
  }
  if (minutes > 0) {
    beforeText += minutes - 0 + " דקות "
  }

  return beforeText
}
