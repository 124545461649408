import React, { Component } from "react"
import { connect } from "react-redux"
import { H1 } from "../../common/H1"
import { LabeledSelect } from "../../common/LabeledSelect"
import { Button } from "../../common/Button"
import { downloadPackageDebitReport } from "../../../actions"
import "./style.scss"

const reportHeader = 'דו"ח חיובי חבילות'
const monthLabel = "בחר חודש"
const yearLabel = "בחר שנה"
const orderTypeLabel = "בחר סוג הזמנה"

class PackageDebitReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedMonth: initMonth(),
      selectedYear: initYear(),
      selectedOrderType: initOrderType(),

      monthOptions: setMonthList(),
      yearOptions: setYearList(),
      orderTypeOptions: setOrderTypeList(),
    }

    this.onDataChange = this.onDataChange.bind(this)
  }

  componentDidMount() {
    if (!this.props.authenticated) {
      this.props.history.push("/")
    }
  }

  onDataChange(property, value) {
    this.setState({ [property]: value })
  }

  onDownloadClick(event) {
    event.preventDefault()
    const { selectedMonth, selectedYear, selectedOrderType } = this.state
    this.props.downloadPackageDebitReport(
      selectedMonth,
      selectedYear,
      selectedOrderType
    )
  }

  render() {
    return (
      <div className="packageDebitReport">
        <H1 className="reports-section-header" title={reportHeader} />
        <form onSubmit={(e) => this.onDownloadClick(e)}>
          <div className="form-group">
            <div className="date-wrapper">
              <LabeledSelect
                extraClass={"label-box"}
                labelText={monthLabel}
                id="month"
                options={this.state.monthOptions}
                value={this.state.selectedMonth}
                emptyOption={false}
                onChange={(e) =>
                  this.onDataChange("selectedMonth", e.target.value)
                }
              />
              <LabeledSelect
                extraClass={"label-box"}
                labelText={yearLabel}
                id="year"
                options={this.state.yearOptions}
                value={this.state.selectedYear}
                emptyOption={false}
                onChange={(e) =>
                  this.onDataChange("selectedYear", e.target.value)
                }
              />
            </div>
          </div>
          <div className="btn-download-wrapper">
            <Button type="submit">הורד</Button>
          </div>
        </form>
      </div>
    )
  }
}

const startYear = 2021
const setYearList = () => {
  let years = []
  let now = new Date()
  now = now.getFullYear()
  for (var i = startYear; i <= now; i++) {
    years[i] = i
  }
  return years
}

const setMonthList = () => {
  let monthNames = [
    "ינואר",
    "פברואר",
    "מרץ",
    "אפריל",
    "מאי",
    "יוני",
    "יולי",
    "אוגוסט",
    "ספטמבר",
    "אוקטובר",
    "נובמבר",
    "דצמבר",
  ]
  let month = []
  for (var i = 0; i < monthNames.length; i++) {
    month[i + 1] = monthNames[i]
  }
  return month
}

const setOrderTypeList = () => {
  let orderType = []
  orderType[13] = "חבילות DHL"
  orderType[14] = "חבילות BAR"
  orderType[15] = "חבילות UPS"
  orderType[6] = "החזרות DHL"
  orderType[17] = "חבילות יד מרדכי"
  orderType[18] = "חבילות גפן מדיקל"
  orderType[7] = "אקסלוט החזרות"
  return orderType
}

const initMonth = () => {
  let now = new Date()
  let month = now.getMonth()
  if (month === 0) {
    month = 12
  }
  return month
}
const initYear = () => {
  let now = new Date()
  let year = now.getFullYear()
  if (now.getMonth() === 0) {
    year--
  }
  return year
}
const initOrderType = () => {
  return 13 //DHL
}

const mapStateToProps = ({ users }) => {
  const { authenticated } = users
  return { authenticated }
}

export default connect(mapStateToProps, { downloadPackageDebitReport })(
  PackageDebitReport
)
