import React from "react"
import Modal from "../Modal"
import { SmallHeader } from "../SmallHeader"
import { Button } from "../../common/Button"
import "./style.scss"

const NotificationModal = ({ show, title, text, onOkClick, extraClass }) => {
  if (!show) {
    return <noscript />
  }

  return (
    <Modal extraClass="noti-modal-wrap">
      <div className={`noti-modal-container ${extraClass}`}>
        {/* <SmallHeader title={title} /> */}
        <div className="noti-modal-content-wrapper">{text}</div>
        <div className="btn-holder">
          <Button extraClass="noti-modal-button" onClick={(e) => onOkClick()}>
            אישור
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default NotificationModal
